/* You can add global styles to this file, and also import other style files */
.flex-search {
  background-color: #f1f3fb !important;
}

.detail-search {
  background-color: #f6f6f6 !important;
}

.panel-search-bg {
  background-color: #ffffff !important;
}

.search-text-italic {
  font-style: italic;
}

.search-text-bold {
  font-weight: bold;
}

.title-text-bold {
  font-weight: bold;
}

.detail-etablissement-nom-text-bold {
  font-weight: 900;
  font-size: 25px;
}

.criteres-text-padding {
  padding-top: 10px;
  padding-bottom: 0px;
}

input::placeholder {
  font-style: italic;
}

.line-separator {
  background-color: gray;
  color: gray;
  height: 2px;
  border-width: 0;
}

.grid-line-separator {
  background-color: cornflowerblue;
  color: cornflowerblue;
  height: 3px;
  border-width: 0;
  width: 150px;
}

.coordonnes-separator {
  background-color: cornflowerblue;
  color: cornflowerblue;
  height: 3px;
  border-width: 0;
  width: 95px;
}

.p-multiselect {
  min-width: 90%;
}

.p-width-100 {
  min-width: 100%;
}

.p-width-50 {
  min-width: 50%;
}

.multiselect-custom-virtual-scroll .p-multiselect {
  min-width: 90%;
}

.img-flag {
  width: 18px;
  margin-right: .5rem;
}

.div-cadre {
  border-radius: 10px;
  border-color: black;
}

.langue-item {
  width: 10px;
  margin-right: .5rem;
}

a {
  text-decoration: none;
}

.a-with-text-color {
  color:blue;
  text-decoration: blue;
}

.p-button.p-button-icon-only {
  border-radius: 0;
}

.image-gallery {
  text-align: center;
  padding: 1rem;
}



